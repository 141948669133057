<template>
    <div>
        <Layout>
            <h1>ManagerFormCreateTemplate</h1>
        </Layout>
        <Footer />
    </div>
</template>

<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Footer from '@/components/Footer/main.vue';

export default {
    name: 'ManagerFormCreateTemplate',
    page: {
        title: 'キャンペーン一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout,
        Footer
    }
};
</script>

<style lang="scss" scoped></style>
